import { FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect, DropdownType } from '../../../core/components/form/OEDropdown';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormik from '../../../core/components/formik/OEFormik';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { getSiteSetting, saveSiteSetting, SiteLanguageNames, SiteLanguages, SiteSetting } from '../../../site/entities/SiteSettings';
import { IProfile } from '../../entities/Profile';
import { usePutProfile } from '../../services/ProfileService';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IProfile> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    const [language, setLanguage] = useState<string>(getSiteSetting(SiteSetting.TranslateLanguage));

    const translateLanguage = (lang: string) => {
        const googleTranslateDropdown: any = document.querySelector(".goog-te-combo");
        if (googleTranslateDropdown && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
        if (googleTranslateDropdown && !googleTranslateDropdown.value && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
    }

    const saveTranslateStorage = (i: string) => {
        saveSiteSetting(SiteSetting.TranslateLanguage, i);
    }

    const handleTranslateClick = (i: string) => {
        translateLanguage(i);
        saveTranslateStorage(i);
        setLanguage(i);
    }

    return (
        <>
            <OEFormInput
                label="UserName" name="userName" value={values.userName}
                disabled={true} errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="First Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Last Name" name="surname" value={values.surname}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Email" name="email" value={values.email}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormInput
                label="Phone Number" name="phoneNumber" value={values.phoneNumber}
                errors={errors} touched={touched} columns={labelColumns}
                required={false} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />
            <OEFormDropdown
                label="Default Language" name="language" value={language}
                defaultSelect={defaultDropdownSelect} alwaysShowDefault={true}
                errors={errors} touched={touched} columns={labelColumns}
                values={[
                    { id: SiteLanguages.English, name: SiteLanguageNames.English },
                    { id: SiteLanguages.Spanish, name: SiteLanguageNames.Spanish }
                ]}
                onChange={handleTranslateClick} required={true} type={DropdownType.String}
            />
        </>
    );
};

const ValidationScheme = Yup.object<IProfile>().shape({
    name: Yup.string().required('First Name is required').min(2).max(250).nullable(),
    email: Yup.string().required('Email is required').min(2).max(250).nullable(),
    surname: Yup.string().required('Last Name is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    profile: IProfile;
    onSuccess: () => void;
}

const UserProfileFormik: React.FunctionComponent<IFormikInfo> = ({ profile, onSuccess }) => {
    const { service, setProfile: setSaveProfile } = usePutProfile();

    const onSave = () => {
        onSuccess();
    };

    const onCancel = () => {
        window.location.href = '/home';
    };

    return (
        <>
            <OEFormik
                item={profile}
                labelColumns={labelColumns}
                progressMessage={ProgressMessages.Profile}
                successMessage={SuccessMessages.Profile}
                onSave={onSave}
                onCancel={onCancel}
                validationSchema={ValidationScheme}
                submitText="Save"
                component={Form}
                setItem={setSaveProfile}
                service={service}
            />
        </>
    );
};

export default UserProfileFormik;
