export const findAndFocusFirstFocusableChild = (parentId: string): void => {
    const parent = document.getElementById(parentId);
    if (!parent) return;

    const focusableSelectors = `
        a[href],
        button:not([disabled]),
        textarea:not([disabled]),
        input[type="text"]:not([disabled]),
        input[type="radio"]:not([disabled]),
        input[type="checkbox"]:not([disabled]),
        select:not([disabled]),
        [tabindex]:not([tabindex="-1"])
    `;
    const firstFocusable =
        parent.querySelector<HTMLElement>(focusableSelectors);

    if (firstFocusable) {
        firstFocusable.focus();
    }
};
