import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useWindowDimensions from '../../../core/hooks/useWindowDimensions';
import { navigateToPageReactRouter } from '../../../core/utilities/URL';
import { getWidgetList, IWidget } from '../../../widgets/entities/Widget';
import { getWidgetGroupList } from '../../../widgets/entities/WidgetGroup';
import { useGetWidgetGroupByCode } from '../../../widgets/services/WidgetGroupService';
import { useGetWidgetsByGroupId } from '../../../widgets/services/WidgetService';
import '../../styles/NavMenu.css';

interface IFooterItem {
    label: string;
    code: string;
    onClick?: MouseEventHandler<HTMLSpanElement>;
}

const SiteFooter: React.FunctionComponent = () => {
    const { service: groupService } = useGetWidgetGroupByCode('WIDGETPAGE');
    const { service, setGroupId: setServiceGroupId } = useGetWidgetsByGroupId();
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const [items, setItems] = useState<IFooterItem[]>([]);

    useEffect(() => {
        if (groupService.result) {
            setServiceGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
        // eslint-disable-next-line
    }, [groupService]);


    useEffect(() => {
        if (service.result) {
            const c: IWidget[] = getWidgetList(service.result).filter(q => !q.isDeleted);
            const i: IFooterItem[] = [];
            i.push(addItem('PRIVACY', c));
            i.push(addItem('DISCLAIMER', c));
            i.push(addItem('TERMS', c));
            i.push(addItem('ACCESSIBILITY', c));
            setItems([...i.filter(q => q.code !== '')]);
        }
        // eslint-disable-next-line
    }, [service]);

    const addItem = (i: string, c: IWidget[]): IFooterItem => {
        let f = c.find(q => q.code === i);
        if (f) {
            return {
                label: f.name,
                code: f.code,
                onClick: () => navigateToPageReactRouter(navigate, `/widget?id=${f ? f.code : ''}`)
            };
        }
        console.log(`ERROR: SiteFooter -> ${i} - (INVALID CODE)`);
        return { code: '', label: `${i} - (INVALID CODE)` };
    }

    const renderResponsiveFooter = () => {
        return (
            <ul className='responsive-footer-container'>
                {items.map((it, idx) => (
                    <li>
                        <span
                            className='link'
                            key={it.label + idx}
                            onClick={it.onClick}
                        >
                            {it.label}
                        </span>
                    </li>
                ))
                }
                <p className='copyright m-t-20 link'>
                    Kentucky Department of Education ® All Rights Reserved.
                </p>
            </ul >
        )
    }

    const renderWebFooter = () => {
        return (
            <div className='info-container-default'>
                <p className='copyright'>Kentucky Department of Education ® All Rights Reserved.</p>
                <nav className='nav-footer-default'>
                    {items.map((it, idx) => (
                        <span className='item' key={it.label + idx} onClick={it.onClick} style={{ marginLeft: `${idx === 0 ? 0 : '60px'}` }}>{it.label}</span>
                    ))}
                </nav>
            </div>

        )
    }

    return (
        <footer className='footer-default'>
            {width < 750 ? renderResponsiveFooter() : renderWebFooter()}
        </footer >

    );
};

export default SiteFooter;


