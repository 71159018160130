import React, { useEffect, useState } from 'react';
import OEAutoComplete from '../../../core/components/form/OEAutoComplete';
import OEDropdown, { IDropdownValues } from '../../../core/components/form/OEDropdown';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import { emptyGUID } from '../../../core/utilities/String';
import { navigateToPage, refreshPage } from '../../../core/utilities/URL';
import { useGetAcademicYears, useGetSchoolDistricts } from '../../../reporting/services/ReportFilterService';
import UserProfile from '../../../security/components/UserProfile';
import { IHeaderText } from '../../entities/HeaderText';
import { getSiteSetting, saveSiteSetting, SiteSetting } from '../../entities/SiteSettings';
import { ICustomRouteProperties } from '../entities/CustomRouteProperties';
import { ISchoolOptionsSelection } from '../pages/Dashboard';
declare global { var msLogout: any; }

interface IComponentInfo {
    toggleCollapse?: () => void;
    collapsed: boolean;
    headerText: IHeaderText;
    isCenter?: boolean;
    pathname?: string;
    headerWidth?: number;
    layout: number;
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void;
    reportingPage?: boolean;
}

const Header: React.FunctionComponent<IComponentInfo> = ({ collapsed, isCenter, pathname, layout, routeProperties, setRouteProperties, reportingPage }) => {
    const { service: orgService, setSearch } = useGetSchoolDistricts();
    const { service: yearService } = useGetAcademicYears(true);

    const [language, setLanguage] = useState<string>(getSiteSetting(SiteSetting.TranslateLanguage));
    const [inputValue, setInputValue] = useState<string>('')
    const [schoolDisctricts, setSchoolDistricts] = useState<ISchoolOptionsSelection[]>([])
    const [years, setYears] = useState<IDropdownValues[]>([]);
    const [year, setYear] = useState<string>(getSiteSetting(SiteSetting.AcademicYear));
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        if (yearService.result) {
            setYears(yearService.result);
        }
        // eslint-disable-next-line
    }, [yearService]);

    useEffect(() => {
        setLoggedIn(!emptyGUID(routeProperties.user.id));
        // eslint-disable-next-line
    }, [routeProperties]);

    useEffect(() => {
        if (years.length) {
            const i = years.find(q => q.id === getSiteSetting(SiteSetting.AcademicYear));
            if (i) {
                setRouteProperties({ ...routeProperties, academicYearID: i.id, academicYear: i.name });
            }
            else {
                setRouteProperties({ ...routeProperties, academicYearID: years[0].id, academicYear: years[0].name });
            }
        }
        // eslint-disable-next-line
    }, [years]);

    useEffect(() => {
        if (orgService.result) {
            const d = [];
            for (const v of orgService.result) {
                d.push({ value: v.id, label: v.name });
            }

            setSchoolDistricts(d)
        }
        // eslint-disable-next-line
    }, [orgService]);


    useEffect(() => {
        const y = years.find(q => q.id === year);
        if (y) {
            setRouteProperties({ ...routeProperties, academicYearID: y.id, academicYear: y.name });
            saveSiteSetting(SiteSetting.AcademicYear, y.id);
        }
        // eslint-disable-next-line
    }, [year]);

    const saveTranslateStorage = (i: string) => {
        saveSiteSetting(SiteSetting.TranslateLanguage, i);
    }

    const onNavigate = (value: string) => {
        navigateToPage(`/embedded-public?pid=c340f7d5-efbd-5fb8-cab8-3a128835f84c&organization=${value}`)
    }

    const onSearch = (val: string) => {
        setInputValue(val)
        if (val.length > 2) {
            setSearch({ keyword: val, academicYear: getSiteSetting(SiteSetting.AcademicYear), isSchool: true });
        }
    }

    const processLogout = () => {
        reportingPage && refreshPage();
    }

    const handleTranslateClick = (lang: string) => {
        if (lang !== language) {
            translateLanguage(lang);
            saveTranslateStorage(lang);
            if (emptyGUID(routeProperties.user.id)) {
                msLogout(getSiteSetting(SiteSetting.MSPath), getSiteSetting(SiteSetting.MSAuthenticationURL), processLogout);
                !reportingPage && setLanguage(lang);
            }
            else {
                setLanguage(lang);
            }
        }
    };

    const translateLanguage = (lang: string) => {
        const googleTranslateDropdown: any = document.querySelector(".goog-te-combo");
        if (googleTranslateDropdown && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
        if (googleTranslateDropdown && !googleTranslateDropdown.value && lang) {
            googleTranslateDropdown.value = lang;
            googleTranslateDropdown.dispatchEvent(new Event('change'));
            saveTranslateStorage(lang);
        }
    }

    const backgroundImageContainer = pathname === "/" || pathname === '/home' ? '#ffffff' : '#f7f7f7';
    const backgroundColorHeader = pathname === "/" || pathname === '/home' ? '#ffffff' : '#007780';

    const renderDefaultHomePageHeader = () => {
        return (
            <div>
                <div className="header-layout" style={{ backgroundColor: backgroundColorHeader }}>
                    <div className='logo-container'>
                        <img
                            height={80}
                            className="header-logo"
                            src={`/images/logo/header-logo-background.png`}
                            alt="KDE Logo"
                        />
                    </div>
                    <div className="dropdown text-end profile-container"
                        style={{
                            justifyContent: 'right',
                            backgroundColor: '#fff'
                        }}>
                        {!loggedIn && (
                            <span
                                className='m-r-10 translate-container header-home-container'
                                onClick={() => handleTranslateClick(language === "en" ? 'es' : 'en')}
                                dangerouslySetInnerHTML={displayHTMLContent(language === "en" ? 'Español' : 'Inglés')}
                            />
                        )}
                        {loggedIn && (
                            <UserProfile className="m-t-10" />
                        )}

                    </div>
                </div >
            </div >
        )
    }

    const renderHeaderLayouts = () => {
        return (
            <div>
                <div className="header-layout" style={{ backgroundColor: '#ffffff' }}>
                    <div style={{
                        width: collapsed ? 80 : 290,
                        backgroundColor: backgroundImageContainer,
                        height: 80
                    }}>
                        <img
                            height={80}
                            className="header-logo"
                            src={`/images/logo/${collapsed ? 'header-logo-collapsed.png' : 'header-logo-background.png'}?v=${Math.random() * 1000}`}
                            alt=""
                        />
                        <div style={{ padding: 20 }}>
                            <div className='separator' />
                        </div>
                    </div>
                    <div className="dropdown text-end profile-container"
                        style={{
                            justifyContent: isCenter ? 'center' : 'space-between',
                            alignItems: 'center'
                        }}>
                        <div className="m-l-10 text-header" style={{ color: '#1B6769' }}>
                            <h3>Report Card Dashboards</h3>
                        </div>
                        <div className="m-l-10 text-header">
                            <div className='box-container flex'>
                                <div className="m-t-5 container-dropdown-data" >
                                    <p style={{ color: 'black', fontWeight: 'bold' }}>School Year</p>
                                </div>
                                <div className="m-l-10" >
                                    <OEDropdown
                                        values={years}
                                        value={year}
                                        onChange={setYear}
                                        className='dropdown-header-year'
                                        ariaLabel="School Year Select (year)"
                                    />
                                </div>
                                <div className='m-l-10'>
                                    <OEAutoComplete
                                        options={schoolDisctricts}
                                        style={{ width: 300, borderRadius: 50, height: 40, textAlign: 'left' }}
                                        onNavigate={onNavigate}
                                        onSearch={onSearch}
                                        placeholder="Search by school or district name"
                                        value={inputValue}
                                        titleStyle='title-search-layout'
                                        ariaLabel="Search field for School and/or District"
                                    />
                                </div>
                                {!loggedIn && (
                                    <span
                                        className='m-l-10 translate-container header-home-container'
                                        onClick={() => handleTranslateClick(language === "en" ? 'es' : 'en')}
                                        dangerouslySetInnerHTML={displayHTMLContent(language === "en" ? 'Español' : 'Inglés')}
                                    />
                                )}
                                {loggedIn && (
                                    <UserProfile className="m-l-10" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <>
            {layout === 2 && (pathname === "/" || pathname === "/home")
                ? renderDefaultHomePageHeader()
                : renderHeaderLayouts()}
        </>
    );
}

export default Header;