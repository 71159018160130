import React, { Children, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ChatBtn from '../../chat/components/ChatBtn';
import ChatWidget from '../../chat/components/ChatWidget';
import { MenuEntity } from '../../core/components/menu/entities/MenuEntity';
import { getMenuChildren } from '../../core/components/menu/Menu';
import useWindowDimensions from '../../core/hooks/useWindowDimensions';
import { redirectLogin } from '../../core/services/Authentication';
import { emptyGUID } from '../../core/utilities/String';
import { useGetAcademicYears } from '../../reporting/services/ReportFilterService';
import { SessionController } from '../components/SessionController';
import { IRoute } from '../entities/Route';
import { getSiteSetting, saveSiteSetting, SiteSetting } from '../entities/SiteSettings';
import { useGetUserMenus } from '../services/MenuService';
import { ICustomRouteProperties } from './entities/CustomRouteProperties';
import DefaultLayout from './layouts/DefaultLayout';
import ReportingLayout from './layouts/ReportingLayout';
import SkipControl from "../components/SkipControl";

export interface ISessionTimer {
    seconds: number;
    isActive: boolean;
    initialized: boolean;
    initializing: boolean;
}

interface IComponentInfo {
    children?: any;
    routes: IRoute[];
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void
}

const Layout: React.FunctionComponent<IComponentInfo> = ({ children, routes, routeProperties, setRouteProperties }) => {
    const { service } = useGetAcademicYears(true);
    const { service: menuService } = useGetUserMenus();

    const [logout] = useState<boolean>(false);
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const [showChat, setShowChat] = useState(false);
    const [siteMenu, setSiteMenu] = useState<MenuEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!getSiteSetting(SiteSetting.PublicAccessible) && emptyGUID(routeProperties.user.id)) {
            redirectLogin();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (service.result) {
            const d = [];
            for (const v of service.result) {
                d.push({ value: v.id, label: v.name });
            }
            saveSiteSetting(SiteSetting.AcademicYear, d[0].value);
        }
    }, [service]);

    useEffect(() => {
        if (menuService.result) {
            const publicMenu: number = getSiteSetting(SiteSetting.MSPublicMenuId);
            const menuOptions = getMenuChildren(menuService.result.items.filter(q => q.isActive), 0, 0, navigate)
            const publicOptions = getMenuChildren(menuService.result.items, publicMenu, publicMenu, navigate)
            setSiteMenu([...menuOptions, ...publicOptions])
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [menuService]);

    const toggleCollapse = () => {
        setMenuCollapsed(!menuCollapsed)
    }

    const isReportingPage = (): boolean => {
        switch (pathname) {
            case '/embedded-report-public':
            case '/embedded-public':
                return true;
            default:
                return false;
        }
    }

    return (
        <SessionController settings={routeProperties.settings} logout={logout} >
            <SkipControl />
            {isReportingPage() && (
                <ReportingLayout
                    toggleCollapse={toggleCollapse}
                    menuCollapsed={width < 600}
                    siteMenu={siteMenu}
                    isLoadingMenu={isLoading}
                    routes={routes}
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                />
            )}

            {!isReportingPage() && (
                <DefaultLayout
                    toggleCollapse={toggleCollapse}
                    menuCollapsed={width < 600}
                    siteMenu={siteMenu}
                    isLoadingMenu={isLoading}
                    routes={routes}
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                >
                    {Children.map(children, (i) => i)}
                </DefaultLayout>
            )}
            {routeProperties.settings.chatbotEnabled && (
                <>
                    <ChatBtn showChat={showChat} setShowChat={setShowChat} />
                    <ChatWidget showChat={showChat} setShowChat={setShowChat} />
                </>
            )}
        </SessionController>
    );
};

export default Layout;
