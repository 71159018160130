import React from 'react';

interface IComponentInfo {
    icon: string;
    className?: string;
    text?: string;
    title?: string;
    hidden?: boolean;
    onClick?: () => void;
    tabIndex?: number;
}

const OEIcon: React.FunctionComponent<IComponentInfo> = ({ icon, text, title, className, hidden, onClick, tabIndex= 0 }) => {
    const isTabIndexNegative = tabIndex === -1;
    return (
        <button
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') onClick && onClick();
            }}
            aria-label={!isTabIndexNegative ? (title || (text ? text : 'Info Icon')) : undefined}
            aria-hidden={isTabIndexNegative ? true : undefined}
            hidden={hidden}
            tabIndex={tabIndex}
            onClick={onClick}
            className={`icon-button ${className ? className : ''} ${
                onClick ? 'icon-action' : ''
            }`}
        >
            <i title={title} className={`${icon} `} />
            {text && <span>{text}</span>}
        </button>
    );
};

export default OEIcon;
