import React from 'react';
import { ICoreComponentInfo, OECoreComponent } from '../../components/OECoreComponent';
import Districts from './reporting/Districts';
import SchoolCompare from './reporting/SchoolCompare';
import Schools from './reporting/Schools';

export interface IComponentInfo extends ICoreComponentInfo {
    name: string;
}

export const OECustomComponent: React.FunctionComponent<IComponentInfo> = ({ name, routeProperties, setRouteProperties }) => {
    switch (name.toLowerCase()) {
        case 'districts':
            return (<Districts setRouteProperties={setRouteProperties} routeProperties={routeProperties} />);

        case 'schools':
            return (<Schools setRouteProperties={setRouteProperties} routeProperties={routeProperties} />);

        case 'schoolcompare':
            return (<SchoolCompare setRouteProperties={setRouteProperties} routeProperties={routeProperties} />);

        default:
            return (<OECoreComponent setRouteProperties={setRouteProperties} name={name} routeProperties={routeProperties} />);
    }
};
