import React from 'react';
import { ICoreComponentInfo } from '../../../components/OECoreComponent';
import OrganizationList from './OrganizationList';

const Schools: React.FunctionComponent<ICoreComponentInfo> = ({ routeProperties, setRouteProperties }) => {
    return (
        <OrganizationList typeId="003" routeProperties={routeProperties} setRouteProperties={setRouteProperties} />
    );
};

export default Schools;