export enum SiteLanguages {
    English = 'en',
    Spanish = 'es'
}

export enum SiteLanguageNames {
    English = 'English',
    Spanish = 'Espa�ol'
}

export enum SiteSetting {
    LogoutRedirect = 'LogoutRedirect',
    SelfUrl = 'SelfUrl',
    SessionTimeout = 'SessionTimeout',
    BrowserCloseDelay = 'BrowserCloseDelay',
    DebugUsers = 'DebugUsers',
    ServerTimeZone = 'ServerTimeZone',
    MSPath = 'MSPath',
    MSPublicLogins = 'MSPublicLogins',
    MSAuthenticationURL = 'MSAuthenticationURL',
    MSPublicPath = 'MSPublicPath',
    MSDownloadPath = 'MSDownloadPath',
    MSPublicMenuId = 'MSPublicMenuId',
    ReportShowDelay = 'ReportShowDelay',
    ChatbotEnabled = 'ChatbotEnabled',
    ShowSessionTimeout = 'ShowSessionTimeout',
    CanDebug = 'CanDebug',
    Maintenance = 'Maintenance',
    HomePageLogoLink = 'HomePageLogoLink',
    PublicAccessible = 'PublicAccessible',
    AcademicYear = 'AcademicYear',
    TranslateLanguage = 'TranslateLanguage',
    MicrostrategyLanguage = 'MicrostrategyLanguage',
    ReportContentWidth = 'ReportContentWidth',
    SchoolCompare = 'SchoolCompare',
    NimbleSelectedMenu = 'NimbleSelectedMenu'
}

export interface ISiteSettings {
    logoutRedirect: string;
    selfUrl: string;
    sessionTimeout: number;
    browserCloseDelay: number;
    debugUsers: string;
    serverTimeZone: string;
    msPath: string;
    msAuthenticationURL: string;
    msPublicPath: string;
    msPublicLogins: string;
    msPublicMenuId: number;
    msDownloadPath: string;
    reportShowDelay: number;
    chatbotEnabled: boolean;
    showSessionTimeout: boolean;
    canDebug: boolean;
    maintenance: boolean;
    homePageLogoLink: string;
    publicAccessible: boolean;
    
}

export const defaultSiteSettings: ISiteSettings = {
    logoutRedirect: '', selfUrl: '', sessionTimeout: 0, browserCloseDelay: 10, debugUsers: '',
    serverTimeZone: '', msPath: '', msPublicPath: '', msPublicMenuId: -1, reportShowDelay: 0,
    chatbotEnabled: false, showSessionTimeout: false, canDebug: false, maintenance: false, msAuthenticationURL: '',
    homePageLogoLink: '', publicAccessible: false, msPublicLogins: '', msDownloadPath: ''
};

export const getSiteSettings = (): ISiteSettings => {
    const s: string = localStorage.getItem('settings') || '';
    return s ? JSON.parse(s) : defaultSiteSettings;
}

export const saveSiteSettings = (i: ISiteSettings) => {
    localStorage.setItem('settings', JSON.stringify(i));
}

export const saveSiteSetting = (key: SiteSetting, value: any) => {
    switch (key) {

        case SiteSetting.AcademicYear:
            localStorage.setItem('academicYear', value);
            break;

        case SiteSetting.TranslateLanguage:
            localStorage.setItem('translateLang', value);
            break;

        case SiteSetting.ReportContentWidth:
            localStorage.setItem('width-content-report', value);
            break;

        case SiteSetting.NimbleSelectedMenu:
            localStorage.setItem('nimbleSelectedMenu', value);
            break;

        case SiteSetting.SchoolCompare:
            localStorage.setItem('schoolCompare', value);
            break;
    }
}

export const getSiteSetting = (key: SiteSetting): any => {
    const i: ISiteSettings = getSiteSettings();
    switch (key) {
        case SiteSetting.LogoutRedirect:
            return i.publicAccessible ? '/' : '/Account/Login?returnUrl=/LoginRedirect';

        case SiteSetting.BrowserCloseDelay:
            return parseInt(i.browserCloseDelay.toString(), 10);

        case SiteSetting.CanDebug:
            return i.canDebug;

        case SiteSetting.ChatbotEnabled:
            return i.chatbotEnabled;

        case SiteSetting.DebugUsers:
            return i.debugUsers;

        case SiteSetting.HomePageLogoLink:
            return i.homePageLogoLink;

        case SiteSetting.Maintenance:
            return i.maintenance;

        case SiteSetting.MSAuthenticationURL:
            return i.msAuthenticationURL;

        case SiteSetting.MSPath:
            return i.msPath;

        case SiteSetting.MSPublicMenuId:
            return parseInt(i.msPublicMenuId.toString(), 10);

        case SiteSetting.MSPublicPath:
            return i.msPublicPath;

        case SiteSetting.MSDownloadPath:
            return i.msDownloadPath || i.msPath;

        case SiteSetting.MSPublicLogins:
            return i.msPublicLogins;

        case SiteSetting.PublicAccessible:
            return i.publicAccessible;

        case SiteSetting.ReportShowDelay:
            return parseInt(i.reportShowDelay.toString(), 10);

        case SiteSetting.SelfUrl:
            return i.selfUrl;

        case SiteSetting.ServerTimeZone:
            return i.serverTimeZone;

        case SiteSetting.SessionTimeout:
            return parseInt(i.sessionTimeout.toString(), 10);

        case SiteSetting.ShowSessionTimeout:
            return i.showSessionTimeout;

        case SiteSetting.AcademicYear:
            return localStorage.getItem('academicYear') || '20232024';

        case SiteSetting.TranslateLanguage:
            return localStorage.getItem('translateLang') || SiteLanguages.English;

        case SiteSetting.ReportContentWidth:
            return localStorage.getItem('width-content-report');

        case SiteSetting.NimbleSelectedMenu:
            return localStorage.getItem('nimbleSelectedMenu');

        case SiteSetting.SchoolCompare:
                return localStorage.getItem('schoolCompare');

                case SiteSetting.MicrostrategyLanguage:
            const c: string = getSiteSetting(SiteSetting.TranslateLanguage);
            switch (c) {
                case 'en':
                    return 'en_us';

                default:
                    return c;
            }

        default:
            return undefined;
    }
}
