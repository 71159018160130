import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../../../core/components/menu/Menu';
import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { defaultHeaderText, IHeaderText } from '../../entities/HeaderText';
import { IRoute } from '../../entities/Route';
import Body from '../components/Body';
import Header from '../components/Header';
import SiteFooter from '../components/SiteFooter';
import { appRootBreadcrumbs, ICustomRouteProperties } from '../entities/CustomRouteProperties';
import useWindowDimensions from '../../../core/hooks/useWindowDimensions';

interface IComponentInfo {
    children?: any;
    toggleCollapse: () => void;
    menuCollapsed: boolean;
    siteMenu: MenuEntity[];
    isLoadingMenu: boolean;
    routes: IRoute[];
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void
}

const DefaultLayout: React.FunctionComponent<IComponentInfo> = ({
    children, isLoadingMenu, siteMenu, toggleCollapse, menuCollapsed, routes, routeProperties, setRouteProperties
}) => {

    const { pathname } = useLocation();
    const [pageName, setPageName] = useState('')
    const [headerText, setHeaderText] = useState<IHeaderText>(defaultHeaderText)
    const [activeLabels, setActiveLabels] = useState<string[]>([]);
    const { width } = useWindowDimensions()

    useEffect(() => {
        if (activeLabels) {
            localStorage.setItem('activeLabels', JSON.stringify(activeLabels));
        }
    }, [activeLabels])


    useEffect(() => {
        const active = localStorage.getItem('activeLabels');
        if (active) {
            setActiveLabels(JSON.parse(active));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (activeLabels) {
            localStorage.setItem('activeLabels', JSON.stringify(activeLabels));
        }
    }, [activeLabels])

    useEffect(() => {
        const active = localStorage.getItem('activeLabels');
        if (active) { setActiveLabels(JSON.parse(active)); }
        const page = routes.filter(rt => rt.path === pathname);
        setPageName(page[0]?.name ?? "")
        if (pathname === '/home' || pathname === '/') {
            setHeaderText({
                ...defaultHeaderText,
                middle: 'Welcome to the Kentucky School Report Card',
                left: 'Report Card'
            });
        } else {
            setHeaderText({
                ...defaultHeaderText,
                middle: 'Kentucky',
                left: 'Report Card'
            });
        }
        // eslint-disable-next-line
    }, [pathname, routes])

    useEffect(() => {
        routeProperties.setPageBreadcrumbs?.(pageName, [...appRootBreadcrumbs, { id: 1, name: pageName }]);
        document.title = `Kentucky Department of Education - ${pageName}`;
        // eslint-disable-next-line
    }, [pageName])

    const getHeight = () => {

        if (pathname === "/" || pathname === "/home") {
            if (width < 750) {
                return 'auto'
            }

            return 'calc(100% - 110px)'
        }
        return 'calc(100% - 80px)'
    }

    return (
        <>
            <Header
                collapsed={menuCollapsed}
                toggleCollapse={toggleCollapse}
                headerText={headerText}
                isCenter={false}
                pathname={pathname}
                routeProperties={routeProperties}
                setRouteProperties={setRouteProperties}
                layout={2}
            />
            <div className='layout-definition report-embedded m-b-0' style={{ height: getHeight() }} >
                {pathname !== '/' && pathname !== '/home' && (
                    <div className='three-tier' style={{ width: 290 }}>
                        <div className="left-nav">
                            <div style={{ padding: '10px 20px 10px 20px' }}>
                                <div className='separator' />
                            </div>
                            <Menu
                                items={siteMenu}
                                collapsed={menuCollapsed}
                                isLoading={isLoadingMenu}
                                activeLabels={activeLabels}
                                setActiveLabels={setActiveLabels}
                            />
                        </div>
                    </div>
                )}
                <Body
                    children={children}
                    collapsed={menuCollapsed}
                    siteMenu={siteMenu}
                    pathname={pathname}
                    headerText={headerText}
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                />
            </div >
            {(pathname === "/" || pathname === "/home") && <SiteFooter />}
        </>
    );
};

export default DefaultLayout;