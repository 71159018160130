import React from 'react';

import { AutoComplete } from 'antd';
import { CSSProperties } from 'styled-components';

export interface IAutoCompleteOptionSelections {
    value: string;
    label: string;
}

interface IComponentInfo {
    options: IAutoCompleteOptionSelections[];
    title?: string;
    onNavigate: (i: string) => void;
    onSearch: (i: string) => void;
    style?: CSSProperties;
    value: string;
    placeholder: string;
    titleStyle?: string;
    ariaLabel?: string;
}

const OEAutoComplete: React.FunctionComponent<IComponentInfo> = ({
    options,
    style,
    title,
    onNavigate,
    onSearch,
    value,
    placeholder,
    titleStyle,
    ariaLabel,
}) => {
    return (
        <>
            {title && <p className={titleStyle ?? ''}>{title}</p>}
            <div style={{ position: 'relative' }}>
                <AutoComplete
                    options={options}
                    onSelect={onNavigate}
                    onSearch={(text) => onSearch(text)}
                    value={value}
                    placeholder={placeholder}
                    style={style}
                    variant="filled"
                >
                    <input
                        role="presentation"
                        title={placeholder}
                        className="search-input"
                        aria-label={ariaLabel}
                        placeholder={placeholder}
                    ></input>
                </AutoComplete>
                <div className="search-icon-container">
                    <i
                        className="fa fa-search"
                        style={{
                            transform: 'rotate(90deg)',
                            height: 20,
                            width: 20,
                            color: 'black',
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default OEAutoComplete;
