import React, { useEffect, useState } from 'react';
import OELink from '../core/components/general/OELink';
import { getParameters } from '../core/components/parameters/entities/OEParameter';
import { ICoreComponentInfo } from '../site/components/OECoreComponent';
import { IMenu, getMenuList } from '../site/entities/Menu';
import { useGetMenuByParentId } from '../site/services/MenuService';
import Layout from './Layout';
import PublicHome from './PublicHome';
import MSEmbeddedPublic from './report-microstrategy/MSEmbeddedPublic';

const PublicPage: React.FunctionComponent<ICoreComponentInfo> = ({ routeProperties, setRouteProperties }) => {
    const { service: menuService, setParentId } = useGetMenuByParentId(); // Step 2 - load menus

    const [pageId, setPageId] = useState<string>('');
    const [activePage, setActivePage] = useState<string>('');
    const [menus, setMenus] = useState<IMenu[]>([]);

    useEffect(() => {
        setParentId(routeProperties.settings.msPublicMenuId);
        // eslint-disable-next-line
    }, [routeProperties.settings.msPublicMenuId]);

    useEffect(() => {
        if (menuService.result !== undefined) {
            setMenus(getMenuList(menuService.result));
        }
        // eslint-disable-next-line
    }, [menuService]);

    useEffect(() => {
        if (menus.length > 0) {
            for (const i of menus) {
                if (window.location.pathname.includes(i.externalUrl)) {
                    setActivePage(i.externalUrl);
                    for (const p of getParameters(i.parameters)) {
                        if (p.key === 'pid') {
                            setPageId(p.value);
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [menus]);

    return (
        <Layout>
            <nav className="py-2 border-bottom">
                <div className={`d-flex flex-wrap`}>
                    <a href="https://www.nd.gov/dpi" className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-decoration-none">
                        <img className="header-logo" src={`/images/header-logo.png?v=${Math.random() * 1000}`} alt="header-logo" />
                    </a>
                </div>
            </nav>
            <header className="bg-dark text-white main-nav">
                {menus.map((item, index) =>
                    <div key={index} className={`dropdown ${item.externalUrl === activePage ? 'public-active' : ''}`}>
                        <OELink icon={item.icon} text={item.name} href={item.externalUrl} className={`nav-link link-light px-3 ${item.externalUrl === activePage ? 'active' : ''}`} aria-current="page" />
                    </div>
                )}
            </header>
            {window.location.pathname.includes('public-home') && (
                <PublicHome />
            )}
            {!window.location.pathname.includes('public-home') && (
                <MSEmbeddedPublic setRouteProperties={setRouteProperties} routeProperties={routeProperties} pageId={pageId} />
            )}
        </Layout>
    );
};

export default PublicPage;