import React, { Children, cloneElement } from 'react';
import { useNavigate } from 'react-router';
import { IBreadcrumb } from '../../../core/components/breadcrumbs/entities/Breadcrumb';
import OEBreadcrumb from '../../../core/components/breadcrumbs/OEBreadcrumb';
import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { navigateToPageReactRouter } from '../../../core/utilities/URL';
import { IHeaderText } from '../../entities/HeaderText';
import { getSiteSetting, SiteSetting } from '../../entities/SiteSettings';
import { ICustomRouteProperties } from '../entities/CustomRouteProperties';
import SiteFooter from './SiteFooter';

interface IComponentInfo {
    children?: any;
    collapsed: boolean;
    siteMenu: MenuEntity[];
    pathname: string;
    headerText: IHeaderText;
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void
}

const pagesWithoutScroll = ['/glossary'];

const Body: React.FunctionComponent<IComponentInfo> = ({ children, siteMenu, pathname = '', routeProperties }) => {

    const navigate = useNavigate()

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        navigateToPageReactRouter(navigate, '/home')
    };

    const updateBreadcrumbs = (i: IBreadcrumb[]) => {
        routeProperties.setPageBreadcrumbs?.(routeProperties.pageName, i);
    }

    const renderHomePage = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className='body-container' id='main-content'
                    style={{
                        width: '100%',
                        overflow: 'overlay',
                    }}>

                    {Children.map(children, (child) => {
                        return cloneElement(child, {
                            children: Children.map(child.props.children, (child_2) => {
                                return cloneElement(child_2, {
                                    element: React.isValidElement(child_2.props.element) ? cloneElement(child_2.props.element, { siteMenu, selectedYear: getSiteSetting(SiteSetting.AcademicYear) }) : child_2.props.element
                                })
                            })
                        })
                    })}
                </div >
            </div>
        )
    }


    const renderAuxiliaryPages = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 290px)' }}>
                <div className='body-container' id='main-content'
                    style={{
                        width: '100%',
                        overflow: pagesWithoutScroll.includes(pathname) ? 'unset' : 'overlay',
                        height: 'calc(100% - 30px)'
                    }}>
                    {routeProperties.pageName && (
                        <div className="breadcumb-container">
                            <OEBreadcrumb
                                isBlack={false}
                                breadcrumbs={routeProperties.breadcrumbs}
                                setBreadcrumbs={updateBreadcrumbs}
                                navigateBreadcrumb={onNavigateBreadcrumb}
                                className='breadcrumb-reporting-page'
                            />
                        </div>
                    )}
                    <div className="subheader" style={{ display: 'flex', alignItems: 'center' }}>
                        <p className='top-left-text'>{routeProperties.pageName}</p>
                    </div>

                    {Children.map(children, (child) => {
                        return cloneElement(child, {
                            children: Children.map(child.props.children, (child_2) => {
                                return cloneElement(child_2, {
                                    element: React.isValidElement(child_2.props.element) ? cloneElement(child_2.props.element, { siteMenu, selectedYear: getSiteSetting(SiteSetting.AcademicYear) }) : child_2.props.element
                                })
                            })
                        })
                    })}
                </div >
                <SiteFooter />
            </div>
        )
    }

    return (pathname === "/" || pathname === "/home") ? renderHomePage() : renderAuxiliaryPages()
}

export default Body;
